
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import {
    TextField
} from '@mui/material';

dayjs.extend(utc);

export const formatDate = (date) => {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
};

export const itemsPerPage = 10

export const DecimalTextField = ({
    label,
    name,
    value,
    onChange,
    fullWidth = true,
    required = false,
    error = false,
    helperText = '',
    disabled = false,
    ...props
}) => {

    const handleChange = (e) => {
        let { value } = e.target;

        // Cambiar coma por punto internamente para manejar los decimales
        value = value.replace(',', '.');

        // Verificar si el valor es un número válido con hasta 2 decimales
        const decimalPattern = /^\d+(\.\d{0,2})?$/;

        if (decimalPattern.test(value) || value === '') {
            onChange(e);  // Solo permite el valor si coincide con el patrón o si está vacío
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'e' || e.key === 'E' || e.key === ',') {
            e.preventDefault();  // Evita la entrada de exponente 'e' o 'E'
        }
    };

    return (
        <TextField
            label={label}
            name={name}
            type="text"
            value={value || ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            fullWidth={fullWidth}
            required={required}
            error={error}
            helperText={helperText}
            disabled={disabled}
            inputProps={{ inputMode: 'decimal', pattern: '^\\d+(\\.\\d{0,2})?$' }}
            {...props}
        />
    );
};